import React, { memo } from 'react';
import moment from 'moment';
import { Row, Col, Dropdown, Button, Menu, Avatar, Tooltip } from 'antd';
import Status from '@components/Tasks/Status';
import UserAvatar from '@components/UserAvatar';
import { useResponsive } from '@hooks/useResponsive';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as CheckboxSvg } from '@assets/icons/check-task.svg';
import { ReactComponent as ClockSvg } from '@assets/icons/clock.svg';
import { ReactComponent as BullhornSvg } from '@assets/icons/MediaViewer/bullhorn.svg';
import { ReactComponent as MagicianWhiteSvg } from '@assets/icons/magician-white.svg';

import './TaskItem.less';
import { useTypedSelector } from '@hooks';
import { taskSelector } from '@redux/selectors/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { openTaskModal } from '@redux/reducers/tasks';
import { useUpdateTask } from '@hooks/tasks';
import { nonNull } from '@helpers/non-null';
import { TaskItemDto } from '@api/Api';
import { useNavigate } from 'react-router-dom';

const menuItems = [
  { label: 'Edit task', key: 'edit' },
  { label: 'Delete task', key: 'delete' }
];

interface ITaskItemProps {
  taskId: string;
  onOpenComment: (id: string) => void;
  onRemoveTask: (task: TaskItemDto) => void;
}

export default memo(function TaskItem({
  taskId,
  onOpenComment,
  onRemoveTask
}: ITaskItemProps) {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const updateTask = useUpdateTask();
  const task = useTypedSelector((state) => taskSelector(state, { taskId }));
  const { isMdWidth } = useResponsive();
  const boardName = task.board.name;
  const lastEventTimeMoment = moment(task.lastEventTime);
  const lastEventTime = lastEventTimeMoment.format(
    lastEventTimeMoment.minutes() === 59 ? 'MMM DD' : 'MMM DD, h:mma'
  );

  const onClickMenu = ({ key }: { key: string }) => {
    if (key === 'edit') {
      dispatch(
        openTaskModal({
          taskId,
          descriptionPlaceholder:
            'Describe your task so your teammates know what to do.'
        })
      );
    } else if (key === 'delete') {
      onRemoveTask(task);
    }
  };

  return (
    <div
      className={`media_viewer_task_item ${
        task.status === 'done' ? 'b-completed' : ''
      }`}
    >
      <div
        className="media_viewer_task_item-checkbox"
        onClick={() =>
          updateTask({
            id: taskId,
            status: task.status === 'done' ? 'todo' : 'done'
          })
        }
      >
        <CheckboxSvg />
      </div>
      <div className="media_viewer_task_item-top">
        {boardName && boardName !== 'no_assigned' && (
          <div className="media_viewer_task_item-group-name">{boardName}</div>
        )}
        <div className="media_viewer_task_item-name">
          {task.assetVersionComment && (
            <Tooltip
              placement="top"
              title="This task was created from a comment"
              overlayClassName="media_viewer_tooltip"
            >
              <BullhornSvg
                className="bullhorn-icon"
                onClick={() =>
                  onOpenComment(nonNull(task.assetVersionComment).id)
                }
              />
            </Tooltip>
          )}
          <Tooltip
            placement="top"
            // eslint-disable-next-line react/no-danger
            title={<span dangerouslySetInnerHTML={{ __html: task.name }} />}
            overlayClassName="media_viewer_tooltip"
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: task.name }}
              onClick={() => {
                const { campaign, board } = task;
                dispatch(
                  openTaskModal({
                    taskId,
                    descriptionPlaceholder:
                      'Describe your task so your teammates know what to do.'
                  })
                );
                navigate(
                  `/campaigns/single/${campaign.id}?tab=tasks&boardId=${board.id}`
                );
              }}
            />
          </Tooltip>
        </div>
        <Dropdown
          overlay={
            <Menu
              selectable={false}
              onClick={onClickMenu}
              items={
                isMdWidth
                  ? [...menuItems, { label: 'Cancel', key: 'cancel' }]
                  : menuItems
              }
            />
          }
          prefixCls="media_viewer_menu"
          overlayClassName="media_viewer_comment_item-overlay media_viewer_mobile-overlay"
          placement="bottomRight"
        >
          <Button className="media_viewer_btn media_viewer_task_item-top-btn">
            <ActionSvg />
          </Button>
        </Dropdown>
      </div>
      <Row
        className="media_viewer_task_item-bottom"
        gutter={16}
        align="middle"
        justify="space-between"
      >
        <Col>
          <Row gutter={16} wrap={false}>
            <Col className="media_viewer_task_item-bottom-item">
              <Status status={task.status} />
            </Col>
            {task.assetVersionComment?.hasAnnotations && (
              <Col className="media_viewer_task_item-bottom-item">
                <MagicianWhiteSvg />
              </Col>
            )}
            <Col className="media_viewer_task_item-bottom-item">
              <Row gutter={8} wrap={false} align="middle">
                <Col>
                  <ClockSvg />
                </Col>
                <Col>{lastEventTime}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {!!task.assignees.length && (
          <Col className="media_viewer_task_item-assignees">
            <Avatar.Group
              maxCount={2}
              size="small"
              prefixCls="ant-avatar-group media_viewer_task_item-assignees"
            >
              {task.assignees.map((x) => (
                <Tooltip
                  key={`media_viewer_task_item-avatar-${x.id}`}
                  title={`Assigned to ${x.user?.name}`}
                  overlayClassName="media_viewer_tooltip"
                  placement="leftBottom"
                >
                  <div>
                    <UserAvatar
                      className="media_viewer_task_item-avatar"
                      isActive={!x.invitationEmail}
                      size={24}
                      src={x.user?.picture?.url || ''}
                      userEmail={x.user?.email || ''}
                      userName={x.user?.name || ''}
                    />
                  </div>
                </Tooltip>
              ))}
            </Avatar.Group>
          </Col>
        )}
      </Row>
    </div>
  );
});
